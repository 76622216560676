import createState from '../../shared/utils/createState';
import { getTagConfig } from '../tagConfig';

export const [getIsGdprConsentEnabled, setIsGdprConsentEnabled] =
  createState(false);

export const [
  getIsImplicitGdprConsentEnabled,
  setIsImplicitGdprConsentEnabled,
] = createState(false);

export const [getIsCmpAppConfigured, setIsCmpAppConfigured] =
  createState(false);

export const getUseExplicitCookiesConsent = () =>
  !getIsImplicitGdprConsentEnabled() &&
  (getTagConfig().useExplicitCookiesConsent ||
    getIsGdprConsentEnabled() ||
    getIsCmpAppConfigured());
